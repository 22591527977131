<template>
  <a-form class="upload-form">
    <BrandSelect :on-change="onChange" :current-brand="currentBrand" />
    <a-upload name="file">
      <a-button class="file-upload">
        <a-icon type="upload" />
        Файл
      </a-button>
    </a-upload>
    <a-button>Загрузить</a-button>
  </a-form>
</template>

<script>
import { mapMutations, mapGetters } from "vuex"

import BrandSelect from "@/components/brand-select/BrandSelect"

export default {
  name: "UploadForm",
  components: {
    BrandSelect,
  },
  computed: mapGetters({
    currentBrand: "csPrices/currentBrand",
  }),
  methods: {
    ...mapMutations({
      chooseBrand: "csPrices/CHOOSE_BRAND",
    }),
    onChange(brandId) {
      this.chooseBrand(brandId)
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-form {
  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-bottom: 10px;
  padding: 10px;

  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;

  .file-upload {
    width: 200px;
  }
}
</style>
