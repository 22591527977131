<template>
  <div class="cs-directories-page">
    <h3>Последние версии справочников:</h3>
    <DirectoriesCsList />
    <Download />
  </div>
</template>

<script>
import DirectoriesCsList from "@/components/directories-cs/List"
import Download from "@/components/directories-cs/download/Download"

export default {
  name: "DirectoriesCS",
  components: {
    Download,
    DirectoriesCsList,
  },
}
</script>

<style scoped></style>
