<template>
  <div class="cs-list">
    <a-table
      bordered
      :columns="columns"
      :data-source="items"
      :pagination="false"
    >
      <a class="download" slot="download">
        Скачать
        <a-icon type="download" />
      </a>
    </a-table>
    <div v-if="!isLoadedMore" class="load-more-button">
      <a-button @click="loadMore">Показать предыдущие загрузки</a-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "List",
  data() {
    return {
      isLoadedMore: false,
    }
  },
  mounted() {
    this.fetchCsDirectories()
  },
  computed: {
    ...mapGetters({
      csDirectories: "csDirectories/list",
      columns: "csDirectories/columns",
      emptyColumns: "csDirectories/emptyColumns",
    }),
    items() {
      return this.csDirectories.filter(
        csDirectory => this.isLoadedMore || csDirectory.isLatest
      )
    },
  },
  methods: {
    ...mapActions({
      fetchCsDirectories: "csDirectories/fetchCsDirectories",
    }),
    loadMore() {
      this.isLoadedMore = true
    },
  },
}
</script>

<style lang="scss" scoped>
.cs-list {
  margin: 30px 0;

  .load-more-button {
    text-align: center;
    margin-top: 12px;
    height: 32px;
    line-height: 32px;
  }

  .download {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
