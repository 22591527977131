<template>
  <div class="brand-select-wrapper">
    <a-select class="select" @change="onChange" :default-value="currentBrand">
      <a-select-option
        v-for="brand in brands"
        :selected="brand.id === currentBrand"
        :key="brand.id"
        :value="brand.id"
      >
        {{ brand.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "BrandSelect",
  props: {
    onChange: {
      type: Function,
      required: true,
    },
    currentBrand: {
      type: Number,
      required: true,
    },
  },
  computed: mapGetters({
    brands: "brands/list",
  }),
  mounted() {
    this.fetchBrands()
  },
  methods: mapActions({
    fetchBrands: "brands/fetchBrands",
  }),
}
</script>

<style lang="scss">
.brand-select-wrapper {
  margin: 30px 0;

  .select {
    width: 200px;
  }
}
</style>
