<template>
  <div class="download">
    <h3>Загрузить:</h3>
    <UploadForm />
  </div>
</template>

<script>
import UploadForm from "@/components/prices-cs/download/UploadForm"

export default {
  components: {
    UploadForm,
  },
}
</script>

<style lang="scss">
.download {
}
</style>
